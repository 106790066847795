<template lang="pug">
  div
    v-snackbar(
      outlined
      absolute
      top
      dismissible
      color="error"
      v-model="orderDetailStatusPostErrors.errors"
    )
      template(v-slot:action="{ attrs }")
        v-btn(
          color="red"
          text
          v-bind="attrs"
          @click="orderDetailStatusPostErrors = []"
        )
          span Close
      div(v-if="orderDetailStatusPostErrors.errors")
        v-icon.error--text.mr-2 mdi-alert
        span {{ orderDetailStatusPostErrors.errors.first() }}
    v-toolbar(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span New Status
    v-row
      v-col(cols="12")
        v-text-field(
          label="ID"
          v-model="id"
          :error-messages="orderDetailStatusPostErrors.id || []"
        )
      v-col(cols="12")
        v-text-field(
          label="Name"
          v-model="name"
          :error-messages="orderDetailStatusPostErrors.name || []"
        )
      v-col(cols="12")
        v-btn(
          color="green"
          dark
          tile
          @click="storeOrderDetailStatus"
          :loading="orderDetailStatusPosting"
        )
          span Save
</template>
<script>
import VuePostApi from '@/libs/classes/VuePostApi.class'
import { postVars } from '@/libs/api-helper.extra'
import orderDetailStatusRepository from '@/repositories/order-detail-status.repository'

const inputVars = () => ({
  id: null,
  name: null,
})

export default {
  name: 'CreateOrderDetailStatus',
  data: () => ({
    ...inputVars(),
    ...postVars('order-detail-status'),
  }),
  methods: {
    storeOrderDetailStatus () {
      if (this.orderDetailStatusPosting) return
      const requestPostVariableNames = this.getRequestPostVariableNames()
      const requestPostApi = new VuePostApi(this, requestPostVariableNames)
      const repository = this.storeRequest()
      requestPostApi.send(repository)
    },
    storeRequest () {
      const data = this.getOrderDetailStatusInputs()
      return orderDetailStatusRepository.store(data)
    },
    getOrderDetailStatusInputs () {
      return {
        id: this.id,
        name: this.name,
      }
    },
    getRequestPostVariableNames () {
      const BASE_NAME = 'orderDetailStatus'
      return {
        loading: `${BASE_NAME}Posting`,
        error: `${BASE_NAME}PostErrors`,
      }
    },
  },
}
</script>